<template>
  <div class="main-wrapper">
    <Sidebar ref="sidebar"/>
    <div class="page-wrapper">
      <Navbar ref="navbar" @toggle-sidebar="toggleSidebar" />
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopOnlyLayout',
  components: {
    Footer: () => import('../components/Footer.vue'),
    Navbar: () => import('../components/Navbar.vue'),
    Sidebar: () => import('../components/SidebarBackoffice.vue'),
  },
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.toggleSidebar();
    },
  },
};
</script>
